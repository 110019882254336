import { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
// components
import Section from 'src/components/Section';
import Icon from 'src/components/Icon';
import { ProgressControl } from 'src/components/Progress/Progress.interface';
import Typography from 'src/components/Typography';
import Progress from 'src/components/Progress';
// hooks
import useClientsData from 'src/cms/data/aboutUs/useClientsData';
import useWindowSize from 'src/hooks/useWindowSize';
// contexts
import CursorContext from 'src/contexts/CursorContext';
// services
import { leadingZero, sliceArray } from 'src/services/helpers';

import * as style from './Clients.module.scss';

const _settings = { progressWidth: 81 };
const arraySize = 4;

const Clients: FC = () => {
  // hooks
  const data = useClientsData();
  const { isMobile } = useWindowSize();
  // state
  const [activeSlide, setActiveSlide] = useState<number>(1);
  // context
  const { cursor } = useContext(CursorContext);
  // refs
  const sliderRef = useRef<Slider>(null);
  const progressControlRef = useRef<ProgressControl>(null);
  // memo
  const sliderSettings = useMemo<Settings>(
    () => ({
      dots: false,
      arrows: false,
      accessibility: false,
      fade: true,
      beforeChange: (_: number, nextSlide: number) => {
        setActiveSlide(nextSlide + 1);
      },
    }),
    []
  );

  const clientList = useMemo(() => sliceArray(data.clientList, arraySize), [
    data.clientList,
  ]);

  const handleMouseEnter = useCallback(() => {
    cursor?.enter();
  }, [cursor]);

  const handleMouseLeave = useCallback(() => {
    cursor?.leave();
  }, [cursor]);

  const handleLeftArrowClick = useCallback(() => {
    sliderRef.current?.slickPrev();
  }, []);

  const handleRightArrowClick = useCallback(() => {
    sliderRef.current?.slickNext();
  }, []);

  const handleSlideMouseEnter = useCallback(() => {
    progressControlRef.current?.pause();
  }, []);

  const handleSlideMouseLeave = useCallback(() => {
    progressControlRef.current?.play();
  }, []);

  const handleProgressFull = useCallback(() => {
    progressControlRef.current?.reset();
    sliderRef.current?.slickNext();
  }, []);

  return (
    <Section title={data.title}>
      <Slider ref={sliderRef} {...sliderSettings}>
        {clientList.map((list, index) => (
          <div
            key={index}
            className={style.list}
            onMouseEnter={handleSlideMouseEnter}
            onMouseLeave={handleSlideMouseLeave}
          >
            {list.map((item, i) => (
              <Icon name={item.icon} key={i} />
            ))}
          </div>
        ))}
      </Slider>
      {clientList.length > 1 && (
        <div className={style.pagination}>
          <button
            onClick={handleLeftArrowClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Icon name='arrowLeft' />
          </button>
          <Typography className={style.pageNumber} variant='h5'>
            {leadingZero(activeSlide)}
          </Typography>
          <Progress
            ref={progressControlRef}
            onProgressFull={handleProgressFull}
            duration={5}
            fixedWidth={isMobile ? undefined : _settings.progressWidth}
          />
          <Typography className={style.pageNumber} variant='h5'>
            {leadingZero(clientList.length)}
          </Typography>
          <button
            onClick={handleRightArrowClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Icon className={style.arrowRight} name='arrowLeft' />
          </button>
        </div>
      )}
    </Section>
  );
};

export default Clients;
