import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import gsap from 'gsap';

import { ProgressControl, Props } from './Progress.interface';
import * as style from './Progress.module.scss';

const Progress = forwardRef<ProgressControl, Props>(
  ({ duration = 1, fixedWidth, onProgressFull }, ref) => {
    const progressRef = useRef<HTMLDivElement>(null);
    const progressAnimationRef = useRef<gsap.core.Tween | null>(null);

    useEffect(() => {
      if (!progressRef.current) {
        return;
      }

      if (progressAnimationRef.current) {
        progressAnimationRef.current?.kill();
      }

      progressAnimationRef.current = gsap.fromTo(
        progressRef.current,
        {
          width: 0,
        },
        {
          width: '100%',
          ease: 'linear',
          duration,
          onComplete: onProgressFull,
        }
      );
    }, [progressAnimationRef, progressRef, duration, onProgressFull]);

    const play = useCallback(() => {
      progressAnimationRef.current?.play();
    }, [progressAnimationRef]);

    const pause = useCallback(() => {
      progressAnimationRef.current?.pause();
    }, [progressAnimationRef]);

    const reset = useCallback(() => {
      progressAnimationRef.current?.repeat();
    }, [progressAnimationRef]);

    useImperativeHandle(
      ref,
      () => ({
        play,
        pause,
        reset,
      }),
      [play, pause, reset]
    );

    return (
      <div className={style.root} style={{ width: fixedWidth }}>
        <div className={style.progress} ref={progressRef} />
      </div>
    );
  }
);

export default memo(Progress);
