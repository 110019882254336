import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Slider, { Settings } from 'react-slick';
import classNames from 'classnames';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
import Icon from 'src/components/Icon/Icon';
import Accordion from 'src/components/Accordion';
// hooks
import useWindowSize from 'src/hooks/useWindowSize';
import useExpertiseData from 'src/cms/data/aboutUs/useExpertiseData';
// context
import CursorContext from 'src/contexts/CursorContext';
import SitePreloaderContext from 'src/contexts/SitePreloaderContext';
// services
import { leadingZero, recurringTimer } from 'src/services/helpers';
// animations
import { drawSvg } from 'src/animations/svg';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';

import * as style from './Expertise.module.scss';

const intervalDuration = 5000;

const Expertise: FC = () => {
  // hooks
  const data = useExpertiseData();
  const { isTablet, isMobile } = useWindowSize();
  // context
  const { cursor } = useContext(CursorContext);
  const { isPageLoaded } = useContext(SitePreloaderContext);
  // state
  const [activeLink, setActiveLink] = useState<number>(0);
  const [activeAccordionLink, setActiveAccordionLink] = useState<number>(0);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  // refs
  const sliderRef = useRef<Slider>(null);
  const intervalRef = useRef<any | null>(null);
  // memo values
  const sliderSettings = useMemo<Settings>(
    () => ({
      dots: false,
      arrows: false,
      accessibility: false,
      fade: true,
      beforeChange: (_: number, nextSlide: number) => {
        setActiveLink(nextSlide);
      },
    }),
    []
  );

  const isActiveTouched = useCallback(
    (index: number) => activeLink === index && isTouched,
    [activeLink, isTouched]
  );

  useEffect(() => {
    intervalRef.current = recurringTimer(() => {
      sliderRef.current?.slickNext();
    }, intervalDuration);

    return () => {
      if (intervalRef.current !== null) {
        intervalRef.current.clear();
      }

      intervalRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (isTablet || isMobile) {
      if (intervalRef.current !== null) {
        intervalRef.current.clear();
      }

      intervalRef.current = null;
    }
  }, [isTablet, isMobile]);

  useEffect(() => {
    const elem: HTMLDivElement | null = document.querySelector(
      `.${style.active}`
    );

    if (elem !== null && !isTouched) {
      elem.style.setProperty('animation', 'none');
      elem.style.setProperty('animation', '');
    }
  }, [isTouched]);

  useEffect(() => {
    data.expertiseList.forEach((item, index) => {
      const drawingElement = document.getElementById(`${item.icon}${index}`);

      if (drawingElement && isPageLoaded) {
        const animation = drawSvg(drawingElement).pause();

        onHTMLElementEnterOnWindow(drawingElement, () => {
          animation.play();
        });
      }
    });
  }, [data.expertiseList, isPageLoaded, activeLink, activeAccordionLink]);

  const handleClick = useCallback(
    (number: number) => () => {
      if (!isTouched) {
        if (intervalRef.current !== null) {
          intervalRef.current.clear();
        }

        setIsTouched(true);
      }

      sliderRef.current?.slickGoTo(number);
    },
    []
  );

  const handleMouseEnter = () => {
    cursor?.enter();
    handleSlideMouseEnter();
  };

  const handleMouseLeave = () => {
    cursor?.leave();
    handleSlideMouseLeave();
  };

  const handleSlideMouseEnter = () => {
    intervalRef.current?.pause();
    setIsPaused(true);
  };

  const handleSlideMouseLeave = () => {
    intervalRef.current?.resume();
    setIsPaused(false);
  };

  const onRowClick = useCallback(
    (index: number) => setActiveAccordionLink(index),
    []
  );

  return (
    <Section className={style.root} title={data.title}>
      {!isTablet && !isMobile && (
        <>
          <div className={classNames(style.section, style.links)}>
            {data.expertiseList.map((item, index) => (
              <div
                key={index}
                onClick={handleClick(index)}
                className={style.linkWrapper}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Typography variant='h4'>
                  <span
                    className={classNames(style.link, {
                      [style.active]: activeLink === index && !isTouched,
                      [style.activeTouched]: isActiveTouched(index),
                      [style.paused]: isPaused,
                    })}
                  >
                    <span
                      className={classNames(style.number, {
                        [style.activeTouched]: isActiveTouched(index),
                      })}
                    >
                      {leadingZero(index + 1)}
                    </span>
                    <span>{item.link}</span>
                  </span>
                </Typography>
              </div>
            ))}
          </div>
          <Slider className={style.section} ref={sliderRef} {...sliderSettings}>
            {data.expertiseList.map((item, index) => (
              <div
                key={index}
                className={style.content}
                onMouseEnter={handleSlideMouseEnter}
                onMouseLeave={handleSlideMouseLeave}
              >
                <div
                  className={classNames(style.iconWrapper, {
                    [style.withMargin]: item.icon === 'customsSolution',
                  })}
                  id={`${item.icon}${index}`}
                >
                  <Icon name={item.icon} />
                </div>
                <div className={style.textWrapper}>
                  <Typography variant='body'>{item.description}</Typography>
                  <ul className={style.stepListWrapper}>
                    {item.servicesList.map((service, i) => (
                      <li key={i} className={style.stepList}>
                        <div className={style.dot} />
                        <Typography className={style.stepListItem} variant='h5'>
                          {service}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </Slider>
        </>
      )}
      <Accordion
        className={style.row}
        variant='alwaysOpen'
        Title={(props) => {
          if (typeof props.text === 'object')
            return (
              <div className={classNames(style.mobileTitle)}>
                <Typography
                  className={classNames(style.text, {
                    [style.activeTouched]: props.activeRow,
                  })}
                  variant='h4'
                >
                  <span
                    className={classNames(style.number, style.mobileNumber, {
                      [style.activeTouched]: props.activeRow,
                    })}
                  >
                    {leadingZero(props.text?.index + 1)}
                  </span>
                  <span>{props.text.link}</span>
                </Typography>
                <div className={style.arrowWrapper}>
                  <Icon name='arrowTop' />
                </div>
              </div>
            );

          return null;
        }}
        Body={(props) => {
          if (typeof props.text === 'object')
            return (
              <div className={style.content}>
                <div
                  className={style.iconWrapper}
                  id={`${props.text.icon}${props.text.index}`}
                >
                  <Icon name={props.text.icon} />
                </div>
                <div className={style.textWrapper}>
                  <Typography variant='body'>
                    {props.text.description}
                  </Typography>
                  <ul className={style.stepListWrapper}>
                    {props.text.list.map((service, i) => (
                      <li key={i} className={style.stepList}>
                        <div className={style.dot} />
                        <Typography className={style.stepListItem} variant='h5'>
                          {service}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );

          return null;
        }}
        list={data.expertiseList.map(
          ({ link, servicesList, ...body }, index) => ({
            title: { index, link },
            body: { index, list: servicesList, ...body },
          })
        )}
        onRowClick={onRowClick}
      />
    </Section>
  );
};

export default Expertise;
