import { FC } from 'react';
// components
import Section from 'src/components/Section';
import StepListProgress from 'src/components/StepListProgress';
// hooks
import useHowWeWorkData from 'src/cms/data/aboutUs/useHowWeWorkData';

import * as style from './HowWeWork.module.scss';

const HowWeWork: FC = () => {
  // hooks
  const data = useHowWeWorkData();

  return (
    <Section className={style.root} title={data.title}>
      <StepListProgress steps={data.itemList} />
    </Section>
  );
};

export default HowWeWork;
