import { FC, useEffect } from 'react';
import classNames from 'classnames';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
// hooks
import useWhatWeCanData from 'src/cms/data/aboutUs/useWhatWeCanData';

import * as style from './WhatWeDo.module.scss';

const WhatWeDo: FC = () => {
  // hooks
  const data = useWhatWeCanData();

  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  return (
    <Section title={data.title} className={style.root}>
      {data.itemList.map((item, index) => (
        <div key={index} className={style.item}>
          <Typography
            className={classNames(style.title, style.animatedText)}
            variant='h3'
          >
            {item.title}
          </Typography>
          <Typography
            className={classNames(style.content, style.animatedText)}
            variant='body'
          >
            {item.description}
          </Typography>
        </div>
      ))}
    </Section>
  );
};

export default WhatWeDo;
