import { useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
// components
import Section from 'src/components/Section';
import Icon from 'src/components/Icon/Icon';
import Typography from 'src/components/Typography';
import Accordion from 'src/components/Accordion';
// animations
import { drawSvg } from 'src/animations/svg';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { appearanceOfText } from 'src/animations/text';
import SitePreloaderContext from 'src/contexts/SitePreloaderContext';
// hooks
import useOurValuesData from 'src/cms/data/aboutUs/useOurValuesData';

import * as style from './OurValues.module.scss';

const OurValues = () => {
  // hooks
  const data = useOurValuesData();
  // refs
  const drawingImageRef = useRef<HTMLDivElement>(null);
  // context
  const { isPageLoaded } = useContext(SitePreloaderContext);

  useEffect(() => {
    if (drawingImageRef.current !== null && isPageLoaded) {
      const animation = drawSvg(drawingImageRef.current).pause();

      onHTMLElementEnterOnWindow(drawingImageRef.current, () => {
        animation.play();
      });
    }
  }, [isPageLoaded]);

  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  return (
    <Section title={data.title} className={style.root}>
      <div className={style.iconWrapper} ref={drawingImageRef}>
        <Icon name='buddhaDrawing' />
      </div>
      <Section className={style.rowWrapper} withMargin={false}>
        <Accordion
          variant='alwaysOpen'
          className={style.row}
          Title={(props) => (
            <div>
              <Typography
                className={classNames(style.title, style.animatedText, {
                  [style.active]: props.activeRow,
                })}
                variant='h3'
              >
                {props.text}
              </Typography>
            </div>
          )}
          Body={(props) => (
            <Typography className={style.content} variant='body'>
              {props.text}
            </Typography>
          )}
          list={data.valueList.map((item) => ({
            title: item.title,
            body: item.description,
          }))}
        />
      </Section>
    </Section>
  );
};

export default OurValues;
