// extracted by mini-css-extract-plugin
export var active = "Expertise-module--active--44647";
export var activeTouched = "Expertise-module--activeTouched--76d89";
export var animatedText = "Expertise-module--animatedText--17dd6";
export var arrowWrapper = "Expertise-module--arrowWrapper--eae89";
export var content = "Expertise-module--content--42da8";
export var description = "Expertise-module--description--10627";
export var dot = "Expertise-module--dot--946bf";
export var iconWrapper = "Expertise-module--iconWrapper--3f5a0";
export var link = "Expertise-module--link--ce6b0";
export var linkWrapper = "Expertise-module--linkWrapper--833a9";
export var links = "Expertise-module--links--615a3";
export var mobileNumber = "Expertise-module--mobileNumber--85e2f";
export var mobileTitle = "Expertise-module--mobileTitle--923d7";
export var number = "Expertise-module--number--6a507";
export var paused = "Expertise-module--paused--19386";
export var progress = "Expertise-module--progress--3a323";
export var root = "Expertise-module--root--97f13";
export var row = "Expertise-module--row--7391e";
export var section = "Expertise-module--section--b4de3";
export var shown = "Expertise-module--shown--701fb";
export var stepList = "Expertise-module--stepList--ee20b";
export var stepListItem = "Expertise-module--stepListItem--56f45";
export var stepListWrapper = "Expertise-module--stepListWrapper--28eb0";
export var text = "Expertise-module--text--b0df5";
export var textWrapper = "Expertise-module--textWrapper--02151";
export var withMargin = "Expertise-module--withMargin--4ed86";